import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
})
export class HeaderUserComponent implements OnInit {
  user_id;
  foto_src: string; 
  user_name;
  tipo;

  progress = {
    certificados : 0,
    porcentagem  : 0
  }

  @Input() public pagina: string;
  constructor(private location: Location, private http: HttpClient) { }

  ngOnInit() {
    this.user_id = localStorage.getItem("userId");
    this.user_name = localStorage.getItem('userName');

    if(localStorage.getItem('foto_src')){
      this.foto_src = localStorage.getItem('foto_src');
    } else{
      this.foto_src = 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
    }
    
  }

  goback(){
    this.location.back();
  }

}
