import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modal-regras',
  templateUrl: './modal-regras.component.html',
  styleUrls: ['./modal-regras.component.scss'],
})
export class ModalRegrasComponent implements OnInit {

  wpp= '551124048900';
  @Input() isOpen;
  @Output() fechar = new EventEmitter();

  constructor(private route: Router) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    
    if(changes.isOpen.currentValue == true){
      this.isOpen = true;
    }
    else{
      this.isOpen = false;
    }
  }

  close() {
    this.fechar.emit({});    
  }

  abrirLinkNoWhatsApp() {
    if (window.confirm('Atenção, você será redirecionado para o WhatsApp.')) {
      var texto = "Desejo mais informações";
      var link = "https://api.whatsapp.com/send?phone="+this.wpp+"&text=" + encodeURIComponent(texto);
      window.open(link, "_blank");
    }
  }



}
