import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { QrScannerComponent } from 'angular2-qrscanner';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'qr-scan',
  templateUrl: './qrscan.component.html',
  styleUrls: ['./qrscan.component.scss'],
})
export class QrScanComponent implements OnInit {

  @Output() leuQr = new EventEmitter();
  @ViewChild(QrScannerComponent, {static: true}) qrScannerComponent: QrScannerComponent;

  constructor(private http: HttpClient) { }

  ngOnInit() {
        
    navigator.mediaDevices.getUserMedia({ video: true }).then( (stream) => {
      // Acesso à câmera concedido
      
      this.qrScannerComponent.getMediaDevices().then(devices => {
        //this.debug += JSON.stringify(devices);
        var videoDevices:MediaDeviceInfo[] = [];
        

          for (const device of devices) {
            //console.log(device);
              if (device.kind.toString().includes('video') && device.label && device.label != '') {
                  videoDevices.push(device);
              }
          }

          if (videoDevices.length > 0){

            let choosenDev;
            for (const dev of videoDevices){
                if( dev.label.toString().includes('back') || dev.label.toString().includes('tras') ){
                    choosenDev = dev;
                    break;
                }
            }
            
            if(choosenDev){
              //console.log('choose '+JSON.stringify(choosenDev))
              this.qrScannerComponent.chooseCamera.next(choosenDev);
            }
            else{
             // console.log('auto '+JSON.stringify(videoDevices[0]))
              this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
            }
         
            this.qrScannerComponent.capturedQr.subscribe(result => {

              if (document.exitFullscreen) {
                document.exitFullscreen();
              }
              this.leuQr.emit({'qr': result.toString()});              
        
            });

            // const video_el = document.querySelector<HTMLVideoElement>('#scanner video');

            // if (video_el) { // Verifica se o elemento foi encontrado e se é o Safari
            //   video_el.setAttribute('playsinline', 'true');
            //   video_el.setAttribute('controls', 'false');
            // }


          }

          else{
            alert("nenhum dispositivo de camera encontrado.");
          }
        

      });

          
    })
    .catch(function(err) {
      // Acesso à câmera negado
      alert('Permissão de câmera negada!');
    });
  }

}
