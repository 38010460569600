import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DescontosComponent } from './descontos/descontos.component';
import { LeilaoComponent } from './leilao/leilao.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { ExtratoComponent } from './extrato/extrato.component';
import { BoletosComponent } from './boletos/boletos.component';
import { CadastroPontosComponent } from './cadastro-pontos/cadastro-pontos.component';

const routes: Routes = [
  
  {    path: 'beneficios',        component: DescontosComponent},
  {    path: 'leilao',            component: LeilaoComponent},
  {    path: 'produtos',          component: ProdutosComponent},
  {    path: 'extrato',           component: ExtratoComponent},
  {    path: 'boleto',            component: BoletosComponent},
  {    path: 'descontos',         component: DescontosComponent},
  {    path: 'qrscan',            component: CadastroPontosComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BenefitsRoutingModule { }
