export class User {
    public id:number;
    public nome:string="";
    public sobrenome:string="";
    public cpf:string = "";
    public cnpj:string = "";
    public email:string="";
    public foto:string="";
    public celular:string="";
    public aniversario:string="";
    public sexo:string="";
    public senha:string="";
    public aceito_term:boolean = true;
    public aceito_info:boolean = true;
    //public aceito_lgpd:boolean = true;
    //public aceito_sms:boolean = true;
    //public aceito_email:boolean = true;
    //public aceito_push:boolean = true;
    //public aceito_whatsapp:boolean = true;
    public status:string= "";
    public preferencias:any="";
    public token_cadastro:any="";
    public confirmou_cadastro:string='';
}