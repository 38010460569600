import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../../app/classes/user';
import { Endereco } from '../../../../app/classes/endereco';
import { AuthService } from '../../../../app/services/auth.service';
import { Location } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilPageComponent implements OnInit {

  user_id;
  token;
  user_name;
  perfil = parseInt(localStorage.getItem('perfil'));

  alterar_senha:boolean = false;

  senha_atual;
  nova_senha;
  nova_senha_c;

  senha_err_msg;
  senha_scc_msg;

  modal_ganhou = false;

  mostrarSenha: boolean = false;
  mostrarNovaSenha: boolean = false;
  mostrarNovaSenhaC: boolean = false;

  usuario:User = new User();
  endereco:Endereco = new Endereco();
  cnpjReadonly: boolean = false;

  tipo;

  moedas_ganhos = 0;

  mostrarMensagemPremio = false;

  public foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  public imageSrc = '/assets/images/imagem-perfil.png';
  myForm = new UntypedFormGroup({
    file: new UntypedFormControl('', [Validators.required]),
    fileSource: new UntypedFormControl('', [Validators.required])
  });

  constructor( renderer: Renderer2, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService, private auth:AuthService, private location:Location) {}

  ngOnInit() {
    this.usuario.cnpj = localStorage.getItem('cnpj') || ''; 
  
    if (this.usuario.cnpj && this.usuario.cnpj.length === 14) {
      this.cnpjReadonly = true;
    } else {
      this.cnpjReadonly = false;
    }
  
    this.user_id = localStorage.getItem('userId');
    this.token = localStorage.getItem("token");
    this.user_name = localStorage.getItem('userName');
  
    if (!this.user_id) {
      this.router.navigate(['/login']);
    }
  
    if (localStorage.getItem('foto_src')) {
      this.foto_src = localStorage.getItem('foto_src');
    }
  
    // Chama funções para carregar dados do usuário e endereço
    this.obter_dados();
    this.obter_endereco();
  }

  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('participantes/obter_dados/' + this.user_id)).subscribe(data => {
      this.usuario = data.usuario;
      this.verificarCamposVazios();
    });
  }
  validarCNPJ(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) {
        return false;
    }

    if (/^(\d)\1+$/.test(cnpj)) {
        return false;
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1))) {
        return false;
    }

    return true;
}
  
  viacep() {

    if (this.endereco.cep.length < 8) {
        return false;
    }

    this.endereco.endereco = 'aguarde...';
    this.http.get<any>('https://viacep.com.br/ws/'+this.endereco.cep+"/json").subscribe((end) => {

        this.endereco.endereco = end.logradouro;
        this.endereco.complemento = end.complemento;
        this.endereco.bairro = end.bairro;
        this.endereco.cidade = end.localidade;
        this.endereco.estado = end.uf;
    });

  }

  obter_endereco() {
    this.http.get(environment.API_URL.concat('participantes/obter_endereco/' + this.user_id)).subscribe((data: any) => {
      if (data.status == 1) {
        this.endereco = data.endereco;
        this.verificarCamposVazios();  // Verifica os campos após carregar o endereço
      } else {
        console.log(data);
      }
    });
  }

  verificarCamposVazios() {
    if (!this.usuario.cnpj || !this.endereco.cep || !this.endereco.endereco || !this.endereco.bairro || !this.endereco.cidade || !this.endereco.estado) {
      this.mostrarMensagemPremio = true;
    } else {
      this.mostrarMensagemPremio = false;
    }
  }

  resetar_form(){
    this.obter_dados();
    this.obter_endereco();
  }


  atualizar_saldos($event) {
    setTimeout(() => {
      this.modal_ganhou = false;
    }, 4000);
  }

  playCoinSound() {
    let audio = new Audio('assets/audio/audioCoin.mpeg');
    audio.play();
  }  

  
  submit_form() {
    const camposObrigatorios = {
      cep: 'O CEP não pode estar vazio.',
      endereco: 'O endereço não pode estar vazio.',
      numero: 'O número do endereço não pode estar vazio.',
      bairro: 'O bairro não pode estar vazio.',
      estado: 'O estado não pode estar vazio.',
      cidade: 'A cidade não pode estar vazia.'
    };
  
    // Validação dos campos obrigatórios
    for (const [key, value] of Object.entries(camposObrigatorios)) {
      if (!this.endereco[key]) {
        alert(value);
        return;
      }
    }
  
    // Validação do CNPJ
    if (!this.usuario.cnpj) {
      alert('O CNPJ não pode estar vazio. Por favor, preencha o CNPJ.');
      return; 
    }
  
    if (this.usuario.cnpj && !this.validarCNPJ(this.usuario.cnpj)) {
      alert('CNPJ inválido. Por favor, verifique os dados.');
      return;
    }
  
    // Envio da atualização do perfil
    this.http.post<any>(environment.API_URL.concat('participantes/update'), 
    {"id": this.user_id, "user":  this.usuario, "endereco": this.endereco }).subscribe(data => {
      
      this.loader.stop();
  
      if(data['status'] == 1){
        alert('Dados atualizados com sucesso');
  
        // Atualiza o nome do usuário no localStorage
        let nome_view;
        const nome = this.usuario.nome.split(' ');
        if (nome.length > 2) {
          nome_view = nome[0] + ' ' + nome[1]; 
        } else {
          nome_view = this.usuario.nome; 
        }
  
        localStorage.setItem("userName", nome_view);
  
        // Verifica se o usuário preencheu os dados e atribui as moedas
        this.verificarPreenchimento();
        
      } else {
        alert('Houve um problema ao atualizar seus dados, tente novamente dentro de alguns minutos.');
      }
    }, error => {
      this.loader.stop();
      console.error('Erro ao atualizar dados:', error);
      alert('Erro ao atualizar os dados. Tente novamente mais tarde.');
    });
  }

  // Função para verificar se o usuário preencheu os dados e ganhar moedas
  verificarPreenchimento() {
    this.http.post(environment.API_URL.concat('pontos/verificar_completar_dados'), {
      id_participante: this.user_id
    }).subscribe((data: any) => {
      if (data.status === 1) {
        alert(`Parabéns, você ganhou ${data.moedas} moedas!`);
        // Atualiza as moedas do usuário
        this.moedas_ganhos = data.moedas;
        this.animacao_saldo();
      } else {
        alert(data.msg);
      }
    }, error => {
      console.error('Erro ao verificar o preenchimento dos dados:', error);
      alert('Erro ao verificar o preenchimento dos dados. Tente novamente mais tarde.');
    });
  }

  animacao_saldo() {
    this.modal_ganhou = true;
    setTimeout(() => { 
      this.modal_ganhou = false 
      location.reload();
    }, 5000);
  }

  atualizar_senha(){
    this.senha_err_msg = '';
    
    if(!this.senha_atual || this.nova_senha.length <= 5){
        this.senha_err_msg = "Preencha os campos corretamente, sua nova senha deve ter no minimo 6 caracteres.";
        return;
    }

    if(this.nova_senha != this.nova_senha_c){
        this.senha_err_msg = "As senhas não conferem.";
        return;
    }

    // Verifica se a senha tem pelo menos 6 dígitos
    if (this.nova_senha.length < 6) {
        this.senha_err_msg = "Sua senha deve ter ao menos 6 caracteres";
        return false;
    }

    else if(this.nova_senha != this.nova_senha_c){
        this.senha_err_msg = "As senhas não conferem";
        return false; 
    }

    // Verifica se a senha contém pelo menos um caractere especial
    var caracteresEspeciais = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (!caracteresEspeciais.test(this.nova_senha)) {
        this.senha_err_msg = "A senha deve ter ao menos 1(um) caractere especial";
        return false;
    }

    // Verifica se a senha contém pelo menos uma letra maiúscula
    var letraMaiuscula = /[A-Z]/;
    if (!letraMaiuscula.test(this.nova_senha)) {
        this.senha_err_msg = "A senha deve ter ao menos 1(uma) letra maiúscula";
        return false;
    }

    this.auth.alterar_senha(parseInt(this.user_id), this.senha_atual, this.nova_senha, this.nova_senha_c).subscribe( data => {
        this.senha_scc_msg = data.mensagem;
    });
  }

  deletar(){

    if(!window.confirm("Ao deletar sua conta você perderá todos os seus dados. Tem certeza que deseja continuar?")){
      return false;
    }

    this.http.post<any>(environment.API_URL.concat("participantes/descadastrar"),
    {id_usuario: this.user_id}).subscribe( data => {
      if(data.status == 1){
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.router.navigate(['/login']);
        location.reload();
      }
    })

  }

  upload_image(event){
    const reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      let ext = file.name.split('.')[1];

      switch(ext){
        case "png":
        case "jpg":
        case "jpeg":

          reader.readAsDataURL(file);
         
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.myForm.patchValue({
              fileSource: reader.result
            });
            this.submit();
          };

        break;

        default:
          alert("Faça upload de uma imagem JPG ou PNG.");
          return;
        break;
      }

    }

  }

  submit(){
    this.http.post(environment.API_URL.concat('participantes/upload_foto/'+this.user_id), this.myForm.value)
    .subscribe(
        (data:any) => {

            if(data.status == 1){
              this.foto_src = data.src;
              localStorage.setItem('foto_src', data.src);
            }else{
                console.log(data);
            }

        }
    );
  }

  goback(){
    this.location.back();
  }

}


