import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PerfilPageComponent } from './perfil/perfil.component';
import { IndiqueComponent } from './indique/indique.component';

const routes: Routes = [
  {    path: 'perfil',            component: PerfilPageComponent},
  {    path: 'indique',           component: IndiqueComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
