import { Component, Injectable, Output, EventEmitter, OnInit, HostListener, ViewChild, Renderer2, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from '../../../../app/classes/login';
import { AuthService } from '../../../../app/services/auth.service';
import { environment } from '../../../../environments/environment';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import * as $ from 'jquery';

@Component({
    selector: 'login-component',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
}) @Injectable({
    providedIn: 'root'
})


export class LoginComponent implements OnInit {

    mostrarSenha = false;

    public eye = faEye;
    modalReference: any;
    public userProfile: any;

    public googleIcon = 'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/52569925-0cb2-4288-9970-1274a39d9a71/76426147-347a-4823-901c-0902c17234a7?org_if_sml=1&force_format=original&w=200';

    public userName: string = '';
    public userEmail: string = '';

     foto_src: string;
     foto_padrao = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

    email: string = "<small> Favor Preencha os campos abaixo </small> <br> <br> Nome: <br><br> Telefone:<br><br>CNPJ:<br><br> Mensagem:  <br>";

    constructor(private renderer: Renderer2, private http: HttpClient, private route: ActivatedRoute, private router: Router, private auth: AuthService, private loader: NgxUiLoaderService, private modalService: NgbModal, private location: Location, private ngZone: NgZone) {
        this.user = new Login;
    }

    ngOnInit() {
        // @ts-ignore
        if (typeof google !== 'undefined') {
            // @ts-ignore
            google.accounts.id.initialize({
              client_id: "176648358869-9uti8bjdaegl2obcm7g04d04iuiol7j7.apps.googleusercontent.com",
              callback: this.handleCredentialResponse.bind(this),
              auto_select: false,
              cancel_on_tap_outside: true,
            });
            // @ts-ignore
            google.accounts.id.renderButton(
              // @ts-ignore
              document.getElementById("google-button"),
              { theme: "outline", size: "large", width: "100%" }
            );
            // @ts-ignore
            google.accounts.id.prompt((notification: PromptMomentNotification) => {});
          } else {
            console.error('A biblioteca do Google ainda não foi carregada');
          }

        if (localStorage.getItem('documento')) {
            this.user.documento = localStorage.getItem('documento');
        }

        if(localStorage.getItem('token')){
            this.router.navigate(['/home']).then(()=>{ location.reload() });
        }

        this.loader.stop();
    }


    public user: Login;
    public loggedIn: boolean;
    public mode: string = 'login';
    public msg: string = '';

    public cpf_r: string = '';

    public senha_atual: string = '';
    public nova_senha: string = '';
    public nova_senha_c: string = '';

    carregando = false;

    decodeJWTToken(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
            
    async handleCredentialResponse(response: any) {
        if (response.credential) {
            this.ngZone.run(() => {

                const decodedToken = this.decodeJWTToken(response.credential)
                sessionStorage.setItem("loggedInUser", JSON.stringify(decodedToken));

                const email = decodedToken.email;
                const nome = decodedToken.name;
                const foto = decodedToken.picture;

                this.http.post<any>(environment.API_URL.concat('cadastro/obter_email/'), {
                    email: email,
                }).subscribe((data: any) => {
                    if (data.status === 1) {
                        if(data.user.nome){
                            localStorage.setItem("userName", data.user.nome);
                            localStorage.setItem('userId', data.user.id);
                            localStorage.setItem('token', data.user.token);
                            localStorage.setItem('tipo', data.user.tipo);
                            localStorage.setItem('id_confirmacao', data.user.id);
                            if(data.user.bloqueado){
                                localStorage.setItem("bloqueado", 'sim');
                            }
                            if (foto) {
                                let foto_src = foto;
                                localStorage.setItem('foto_src', foto_src);
                            }
                            else if(!foto && data.user.foto){
                                let foto_src = environment.API_URL.replace('/api/', '/static/participantes/' + data.user.foto);
                                localStorage.setItem('foto_src', foto_src);
                            }
                            else{
                                localStorage.removeItem('foto_src');
                            }
                            this.router.navigate(['/home']).then(() => { location.reload();  });

                        }
                    } else {
                        this.router.navigate(['/cadastro'], { queryParams: { email: email, nome: nome }});
                    }
                });
            });
        } else {
            console.log("Error: " + response.error);
        }
    }  

    toggle_pass(){

        const senhaInput = $('#senha');

        if (senhaInput.attr('type') === 'password') {
            senhaInput.attr('type', 'text');
        } else {
            senhaInput.attr('type', 'password');
        }

    }

    login() {

        this.carregando = true;

        if (!this.user.documento) {
            this.msg = 'Preencha o campo CPF';
            this.carregando = false;
            return;
        }

        /* if(this.user.documento == '00000000000' && this.user.senha == '123'){
            this.router.navigate(['/primeiro-acesso']);
            return;
        } */

        this.loader.start();

        this.auth.login(this.user).subscribe(
            (data: any) => {
                //console.log(data);
                //return;
                if (data.nome) {

                    localStorage.setItem('id_confirmacao', data.id);
                    /* if (data.confirmou_cadastro == 'N') {
                        this.router.navigate(['/confirmacao']);
                        return;
                    } */

                    var nome_view;
                    let nome = data.nome.split(' ');
                    if (nome.length > 2) {
                        nome_view = nome[0] + ' ' + nome[1];
                        if(nome[1].length <= 3 && nome[2] != undefined){
                            nome_view = nome_view + " " + nome[2];
                        }
                    }
                    else {
                        nome_view = data.nome;
                    }

                    localStorage.setItem("userName", nome_view);
                    localStorage.setItem('userId', data.id);
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('tipo', data.tipo);

                    if(data.bloqueado){
                        localStorage.setItem("bloqueado", 'sim');
                    }

                    if (data.foto) {
                        let foto_src = environment.API_URL.replace('/api/', '/static/participantes/' + data.foto);
                        localStorage.setItem('foto_src', foto_src);

                    }
                    else{
                        localStorage.removeItem('foto_src');
                    }

                    this.router.navigate(['/home']).then(() => { location.reload();  });
            
                }
                else if (data.texto) {
                    this.msg = data.texto;
                    this.loader.stop();
                }
            }
        )
    }

    /* mudar_senha() {
        let user_id = parseInt(localStorage.getItem('userId'));
        this.auth.alterar_senha(user_id, this.senha_atual, this.nova_senha, this.nova_senha_c).subscribe(data => {
            this.msg = data.mensagem;
        })
    }
    */

    esqueci_senha() {
        this.loader.start();
        $("#recuperar_senha button").attr('disabled', true);
        this.auth.recuperar_senha(this.user.documento).subscribe(data => {
            $("#recuperar_senha button").attr('disabled', false);
            this.msg = data.mensagem;
            this.loader.stop();
        });

    }


    toggle_senha() {
        let tipo = $('#senha').attr('type');

        switch (tipo) {
            case 'password':
                $("#senha").attr('type', 'text');
                break;
            case 'text':
                $("#senha").attr('type', 'password');
                break;
        }
    }

    regulamento;
    abrir_regulamento() {
        this.regulamento = true;
    }

}