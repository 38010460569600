import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import LocalePt from '@angular/common/locales/pt';
registerLocaleData(LocalePt, 'pt');

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { ContentRoutingModule } from './content-routing.module';
import { LandingComponent } from './landing/landing.component';
import { RankingComponent } from './ranking/ranking.component';
import { FaqComponent } from './faq/faq.component';
import { OngsComponent } from './ongs/ongs.component';
import { HomeComponent } from './home/home.component';
import { IndexComponent } from './index/index.component';
import { IncludesModule } from '../includes/includes.module';



@NgModule({
  declarations: [
    IndexComponent,
    HomeComponent,
    OngsComponent,
    FaqComponent,
    RankingComponent,
    LandingComponent,
   ],
  imports: [
    ContentRoutingModule,
    IncludesModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    CurrencyMaskModule,
    NgxUiLoaderModule,
    FontAwesomeModule,
    AutocompleteLibModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: LOCALE_ID, useValue: 'pt-BR'},
  ],
})
export class ContentModule { }
