import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'qr-scan3',
  templateUrl: './qrscan3.component.html',
  styleUrls: ['./qrscan3.component.scss'],
})
export class Qrscan3Component implements OnInit {
  
  @Output() leuQr = new EventEmitter<string>(); 
  carregando = false;

  formats: BarcodeFormat[] = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX]; // Suporta QR Code e DataMatrix

  constructor() {}

  ngOnInit() {}

  public onEvent(qrResult: string) {
    if(!this.carregando){
      this.leuQr.emit(qrResult);
      this.carregando = true;
      setTimeout(() => this.carregando = false, 60000); // Reativa após 60 segundos
    }
  }

  // Método para resetar o estado de leitura (opcional)
  public resetLeitura() {
    this.carregando = false;
  }
}
