import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit {

  safeURL;
  parceiros;

  //perfil;
  foto_src: string;
  foto_padrao = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  user_id;
  user_name;

  pontos;
  moedas;
  loaded_data = false;
  //@Output() timer_video = new EventEmitter();


  public slideOpts = {
    slidesPerView: 1,
    speed: 300
  };

  pontos_ganhos;
  moedas_ganhos;
  modal_ganhou = false;

  modal;
  modal_ativo;
  modal_banner_url;


  modalAberto: boolean = false;
  modalAtencao: boolean = false;
  modalExpirado: boolean = false;
  modalParcela: boolean = false;
  modalRegras: boolean = false;

  sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
    },
  };

  sliderOptionsCards = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination-cards',
    },
  };

  sliderOptionsShares = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
    },
    pagination: {
      el: '.swiper-pagination-shares',
    },
  }

  has_quiz: boolean = false;
  etapa_quiz = 1;

  quiz: any;
  perguntas: any;
  pergunta_atual = 0;
  respostas: any = [];
  resposta_correta: string;
  acertou: string;

  has_video: boolean = false;
  video: any;

  drive: any;

  showNps = false;
  activeStars: boolean[] = [false, false, false, false, false];
  starImages: string[] = ['assets/estrela-vazada.svg', 'assets/estrela-vazada.svg', 'assets/estrela-vazada.svg', 'assets/estrela-vazada.svg', 'assets/estrela-vazada.svg'];

  propaganda;
  regulamento = false;
  loading = false;
  modal_pwa = false;
  modal_pwa_ios = false;
  //modal_qr = false;
  mostrar_pwa;
  pwa_interval;

  npsMsg;  
  nps;
  deferredPrompt: any;

  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;

  constructor(renderer: Renderer2, private route: ActivatedRoute, private router: Router, private modalService: NgbModal,
    private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService, private platform: Platform) {
  }


  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if (!this.user_id) {
      this.router.navigate(['/login']);
    }

    let foto_src = localStorage.getItem('foto_src');
    if (!foto_src) {
      this.foto_src = this.foto_padrao;
    }

    if (!localStorage.getItem('votou_nps')) {
      this.showNps = true;
    }

    this.pwa_interval = setInterval(()=>{ this.obter_pwa(); }, 2500)

   /* MODAL QR desabilitado
      if (!localStorage.getItem('resposta_qr')) {
        this.modal_qr = true;
      } 
    */

    this.obter_dados();
    this.obter_modal();
    this.obter_video();
    this.obter_quiz();
    this.obter_drive();

    //this.obter_indicados();
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: any) {
    event.preventDefault();
    this.deferredPrompt = event;
  }

  obter_pwa() {
    const isInStandaloneMode = () =>
      window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
  
    const isIOS = this.platform.is('ios');

    const respostaAnterior = localStorage.getItem('resposta_pwa');

    if (respostaAnterior === 'sim' || respostaAnterior === 'nao') {
      this.mostrar_pwa = false;
      this.modal_pwa_ios = false;
      return;
    }
  
  
    console.log(isInStandaloneMode(), isIOS, console.log(this.deferredPrompt));
    if (!isInStandaloneMode() && !isIOS && this.deferredPrompt) {
      this.mostrar_pwa = true;
      clearInterval(this.pwa_interval);
    }
    if (isIOS) {
      this.modal_pwa_ios = true;
    }
  }
  
  responder_pwa(resposta) {
    switch (resposta) {
      case 'nao':
        localStorage.setItem('resposta_pwa', 'nao');
        this.mostrar_pwa = false;
        break;
      case 'sim':
        localStorage.setItem('resposta_pwa', 'sim');
        this.mostrar_pwa = false;
        this.add_pwa();
        break;
    }
  }
  
  add_pwa() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice
        .then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('O aplicativo foi instalado');
          } else {
            console.log('O usuário cancelou a instalação');
          }
          this.deferredPrompt = null;
        });
    } else {
      console.error('O prompt de instalação não está disponível.');
      // Possivelmente reativar a função com uma mensagem de erro
    }
  }



  responder_pwa_ios() {
    localStorage.setItem('resposta_pwa', 'sim');
    this.modal_pwa_ios = false;
  }


/* MODAL qr Desabilitado 

  responder_qr() {
    localStorage.setItem('resposta_qr', 'sim');
    this.router.navigate(['/qrscan']);
    this.modal_qr = false;
  }

  fechar_qr() {
    localStorage.setItem('resposta_qr', 'sim');
    this.modal_qr = false;
  } 
  
  */



  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
      this.loaded_data = true;
    });
  }

  obter_video() {

    this.has_video = false;
    this.video = null;

    this.http.get<any>(environment.API_URL.concat('videos/index/' + this.user_id)).subscribe(data => {

      if (data.status == 1) {
        this.has_video = true;
        this.video = data.video;

        /* setTimeout(()=>{
          this.framevideo();
        }, 500); */

      }

      this.loader.stop();
    });
  }

  obter_quiz() {
    this.loader.start();
    this.http.get<any>(environment.API_URL.concat('quiz/index/' + this.user_id)).subscribe(data => {

      if (data.status == 1) {
        this.has_quiz = true;
        this.quiz = data.quiz;
        this.perguntas = data.perguntas;

        if (data.quiz.url_material) {
          this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.quiz.url_material);
        }
      }

      this.loader.stop();
    });
  }

  obter_drive() {
    this.loader.start();
    this.http.get<any>(environment.API_URL.concat('drive/index/' + this.user_id)).subscribe(data => {

      if (data.status == 1) {
        this.drive = data.grupos;
      }

      this.loader.stop();
    });
  }

  obter_indicados() {

    let url = 'cadastro/obter_indicados/' + this.user_id;

    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      if (data.status == 1) {

        data.indicados.forEach((indicado) => { 
          if(indicado.liberado == 1 && indicado.notificou == 0){
            this.modalParcela = true;

            setTimeout(()=>{
              this.http.post(environment.API_URL.concat('cadastro/notificou_indicacao'),{  cpf: indicado.cpf }).subscribe( (data) => { 
              });
            }, 2000)
          } 
        });
      }
    });

  }

  downloadDrive(id: number) {

    this.http.post<any>(environment.API_URL.concat('drive/marcar_download/' + this.user_id + '/' + id), {
      id_participante: this.user_id,
    }).subscribe(data => {
      if (data.status == 1) {
        this.moedas_ganhos = data.moedas ?? 0;
        this.pontos_ganhos = data.pontos ?? 0;

        const index = this.drive.indexOf(this.drive.find(d => { return d.id == id }));
        this.drive[index]['baixou'] = true;
        this.animacao_saldo();
      }
      else {
        console.log('Erro');
      }
    });

  }

  proxima_pergunta() {

    if (this.respostas.length != (this.pergunta_atual + 1)) {
      alert('Selecione a resposta');
      return;
    }

    this.pergunta_atual += 1;
  }

  responder_quiz() {

    if (this.perguntas.length != this.respostas.length) {
      alert('Selecione a resposta');
      return;
    }

    this.loading = true;

    this.http.post<any>(environment.API_URL.concat('quiz/responder_quiz/'), {
      id_usuario: this.user_id,
      id_quiz: this.quiz.id,
      respostas: this.respostas
    }).subscribe(data => {

      this.pontos_ganhos = data.pontos;
      this.moedas_ganhos = data.moedas;
      this.acertou = data.acertou;
      this.resposta_correta = data.resposta_correta;

      if (data.acertou == 'S') {
        this.animacao_saldo();
      }

      this.etapa_quiz = 2;

    });

  }

  responder_enquete() {

    if (this.perguntas.length != this.respostas.length) {
      alert('Selecione a resposta');
      return;
    }

    this.http.post<any>(environment.API_URL.concat('quiz/responder_enquete'), {
      id_usuario: this.user_id,
      id_quiz: this.quiz.id,
      respostas: this.respostas
    }).subscribe(data => {
      this.pontos = data.saldo_pontos;

      this.pontos_ganhos = 10;
      this.moedas_ganhos = 20;
      this.animacao_saldo();

      this.etapa_quiz = 2;
    });
  }

  assistir_video() {
    localStorage.setItem('timer', String(new Date().getTime() / 1000 + 5));
    this.propaganda = true;
  }

  assistiu_video(event) {

    this.pontos_ganhos = event.pontos;
    this.moedas_ganhos = event.moedas;

    this.http.post<any>(environment.API_URL.concat('videos/assistir_video'), {
      id_participante: this.user_id,
      id_video: this.video.id
    }).subscribe(data => {
      if (data.status == 1) {
        this.propaganda = false;
        this.animacao_saldo();
        this.obter_video();
      }
      else {
        console.log('Erro');
      }
    });

  }

  animacao_saldo() {
    this.modal_ganhou = true;
    setTimeout(() => { this.modal_ganhou = false }, 2800);
  }

  atualizar_saldos($event) {
    setTimeout(() => {
      this.obter_dados();
      this.modal_ganhou = false;
    }, 3000);
  }

  obter_modal() {
    this.http.post<any>(environment.API_URL.concat('acoes/modal'), {
      id_usuario: this.user_id
    }).subscribe((data: any) => {
      if (data.status == 1) {


        const key = 'viumodal_' + data.modal.titulo;
        const frequencia = data.modal.frequencia_exibicao;

        if (frequencia == 'unica' && localStorage.getItem(key) == '01') {
          return;
        }

        if (frequencia == 'diaria') {
          const ultimaVisualizacao = localStorage.getItem(key);
          const hoje = new Date().toLocaleDateString();
          if (ultimaVisualizacao == hoje) {
            return;
          }
        }

        //console.log(data.modal);
        this.modal_ativo = data.modal;
        this.modal_banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.modal_ativo.banner);
        setTimeout(() => {
          this.abrir_modal_acao();
        }, 1000);

        const dataVisualizacao = frequencia == 'diaria' ? new Date().toLocaleDateString() : '01';
        localStorage.setItem(key, dataVisualizacao);

      }

    });
  }

  abrir_modal_acao() {
    this.modalService.open(this.modalAcao, { windowClass: 'modal-acao', size: 'md' });
  }

  abrirModal() {
    this.modalAberto = true;
  }

  fecharModal() {
    this.modalAberto = false;
  }

  fecharModalAtencao() {
    this.modalAtencao = false;
  }

  fecharModalExpirado() {

    this.modalExpirado = false;

    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('foto_src');
    localStorage.removeItem('tipo');
    localStorage.removeItem('bloqueado');

    setTimeout(() => {
     // this.router.navigate(['/login']);
    }, 500)

  }

  fecharModalParcela() {

    this.modalParcela = false;

  }

  addToHomeScreen() {
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });

  }


  selecionarNps(index) {
    for (let i = 0; i < this.starImages.length; i++) {
      this.starImages[i] = i <= index ? 'assets/estrela-preenchida.svg' : 'assets/estrela-vazada.svg';
    }
    this.nps = index + 1;
  }


  votarNps() {

    this.loading = true;
    this.http.post<any>(environment.API_URL.concat('acoes/votar_nps'), {
      id_participante: this.user_id,
      nps: this.nps
    }).subscribe(data => {
      if (data.status == 1) {
        this.showNps = false;
        this.npsMsg = true;
        localStorage.setItem('votou_nps', this.nps);
      }
      else {
        console.log('Erro');
      }
    });

  }

  framevideo() {
    var video = document.getElementById("video_previa") as HTMLVideoElement;

    video.addEventListener("loadedmetadata", function () {
      // Defina o tempo do frame desejado em segundos
      var frameTime = 5;

      // Use um evento de tempo para verificar quando o vídeo atinge o quadro desejado
      video.addEventListener("timeupdate", function () {
        if (video.currentTime >= frameTime) {
          // Pause o vídeo quando atingir o quadro desejado
          video.pause();
        }
      });
    });
  }

  abrir_regulamento(){
    this.regulamento = true;
  }

}


