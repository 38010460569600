import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-descadastrar',
  templateUrl: './descadastrar.component.html',
  styleUrls: ['./descadastrar.component.scss'],
})
export class DescadastrarComponent implements OnInit {

  id_usuario;
  user_name;

  descadastrou = false;

  constructor(private router:Router, private http:HttpClient) { }

  ngOnInit() {

    this.id_usuario = localStorage.getItem('userId');    
    this.user_name = localStorage.getItem('userName');
    
  }

  descadastrar(){
    this.http.post<any>(environment.API_URL.concat('participantes/descadastrar'), 
    {id_usuario: this.id_usuario}).subscribe(data => {
      if(data.status == 1){
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.descadastrou = true;
      }else{
        alert(data.msg);
      }
    });
  }
}
