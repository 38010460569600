import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import LocalePt from '@angular/common/locales/pt';
registerLocaleData(LocalePt, 'pt');

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { IncludesModule } from './modulos/includes/includes.module';
import { AuthModule } from './modulos/auth/auth.module';
import { ContentModule } from './modulos/content/content.module';
import { BenefitsModule } from './modulos/benefits/benefits.module';
import { ProfileModule } from './modulos/profile/profile.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TokenInterceptor } from './token.interceptor';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule, 
        IncludesModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AuthModule,
        ContentModule,
        BenefitsModule,
        ProfileModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production, //enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:10000'
          })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR'},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
