import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import * as $ from 'jquery';
import { windowWhen } from 'rxjs/operators';
import { IonSlides } from '@ionic/angular';
@Component({
  selector: 'app-primeiro-acesso',
  templateUrl: './primeiro-acesso.component.html',
  styleUrls: ['./primeiro-acesso.component.scss'],
})


export class PrimeiroAcessoComponent implements OnInit {

  user_name;
  user_id;
  perfil;
  @ViewChild('slides') slides: IonSlides;

  constructor(private loader: NgxUiLoaderService, private http:HttpClient, private router:Router) { }

  ngOnInit() {

    $("ion-menu").hide();
    this.validar();

    this.user_id = localStorage.getItem('userId');

    setInterval(() => {
      let slide_ativo = $(".swiper-slide-active").attr('id');

      switch(slide_ativo){
        case"slide1":
          $(".background-banner").css('background-color', "#EB6723");
        break;
        case"slide2":
          $(".background-banner").css('background-color', "#4BC1C5");
        break;
        case"slide3":
          $(".background-banner").css('background-color', "#ea7f22");
        break;
        case"slide4":
          $(".background-banner").css('background-color', "#88FBFF");
        break;
        case"slide5":
          $(".background-banner").css('background-color', "#fcbd33");
        break;

        default:
          $(".swiper-pagination-bullet-active").css('background-color', "#f5a623");
        break;
      }
    }, 500);
  }

  validar(){

    const id_usuario = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if(!id_usuario)
      //this.router.navigate(['/login']);

    this.http.get<any>(environment.API_URL.concat('participantes/obter_dados/'+id_usuario)).subscribe( data => {
      if(data.status == 1){
        if(data.usuario.primeiro_acesso == 'N'){
          this.router.navigate(['/home']);
        }
      }
      else{
        return true;
      }
    });
 
  }

  primeiro_acesso(){

    this.http.post<any>(environment.API_URL.concat('participantes/update/'),
    {'id' : this.user_id, user: {'primeiro_acesso' : 'N'} }).subscribe( data => {

      this.router.navigate(['/home']).then( ()=>{
        location.reload();
      });
      
    });

  }
  
  slidePrev() {
    this.slides.slidePrev();
  }

  slideNext() {
    this.slides.slideNext();
  }
}
