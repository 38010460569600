import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { Platform } from '@ionic/angular';

//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {

  public is_logged = false;
  public regulamento;
  public regulamento_sorteio;
  public perfil = parseInt(localStorage.getItem('perfil'));

  public menu = [ 
    { title: 'Home',                    url: '/home'},
    { title: 'QR Code',                 subtitle: 'Cadastre produtos ZEN!',       url: '/qrscan'    },
    { title: 'Indique',                 subtitle: 'Indique amigos',             url: '/indique'   },
    //{ title: 'Leilao',                  subtitle: 'Faça seu lance!',            url: '/leilao'    },
    { title: 'Resgate',                 subtitle: 'Produtos exclusivos',        url: '/produtos' },
    { title: 'Descontos',               subtitle: 'Aproveite os descontos',     url: '/beneficios'    },
    { title: 'Perfil',                  subtitle: 'Seus dados',                 url: '/perfil'    },
    //{ title: 'Blog',                    subtitle: 'Acompanhe matérias',         url: '/blog'    },
    //{ title: 'Boleto',                  subtitle: 'Pague seu boleto',           url: '/boleto'  },
  ];

  private routerSubscription: Subscription;

  constructor( private platform: Platform, private router : Router, private updates: SwUpdate
   // private splashScreen: SplashScreen,
   // private statusBar: StatusBar
  ) {
    this.updates.available.subscribe(event => {
      this.updates.activateUpdate().then(() => document.location.reload());
    });
    
   }

  ngOnInit(): void {

    if(localStorage.getItem("userId")){
      this.is_logged = true;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.trackPageView();
      }
    });

  }

  trackPageView() {
    setTimeout(() => {
      if (typeof (window as any).mt !== 'undefined') {
        (window as any).mt('send', 'pageview');
      }
    }, 400);
  }


  abrir_regulamento(){
    this.regulamento = true;
  }
  
  abrir_regulamento_sorteio(){
    this.regulamento_sorteio = true;
  }

  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('foto_src');
    localStorage.removeItem('tipo');
    localStorage.removeItem('bloqueado');

    setTimeout(()=>{
      this.router.navigate(['/login']);
    },500)
  }

  fechar_menu(){
    let menu =  $('ion-menu');
    menu.removeClass('show-menu');
    let backdrop =  $('ion-backdrop');
    backdrop.removeClass('show-backdrop'); 
  }



}
