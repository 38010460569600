import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import LocalePt from '@angular/common/locales/pt';
registerLocaleData(LocalePt, 'pt');

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CountdownModule } from 'ngx-countdown';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { QRCodeModule } from 'angularx-qrcode';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';


import { HeaderComponent } from './header/header.component';
import { PopupVideoComponent } from './popup-video/popup-video.component';
import { PerfilComponent } from './perfil/perfil.component';
import { FooterComponent } from './footer/footer.component';
import { RegulamentomodalComponent } from './regulamentomodal/regulamentomodal.component';
import { ContagemRegressivaComponent } from './contagem_regressiva/contagem_regressiva.component';
import { LoaderComponent } from './loader/loader.component';
import { HeaderUserComponent } from './header-user/header-user.component';
import { CardRankingExtratoComponent } from './card-ranking-extrato/card-ranking-extrato.component';
import { PlacarComponent } from './placar/placar.component';
import { CadastroCpfComponent } from './cadastro-cpf/cadastro-cpf.component';
import { RegulamentoSorteioComponent } from './regulamento-sorteio/regulamentosorteio.component';
import { ModalRegrasComponent } from './modal-regras/modal-regras.component';
import { BannerPrincipalComponent } from './banner-principal/banner-principal.component';
import { DoarComponent } from './doar/doar.component';
import { ModalComponent } from './modal/modal.component';
import { QrScanComponent } from './qrscan/qrscan.component';
import { Qrscan2Component } from './qrscan2/qrscan2.component';
import { Qrscan3Component } from './qrscan3/qrscan3.component';
import { ModalEnderecoComponent } from './modal-endereco/modal-endereco.component';


@NgModule({
  declarations: [
    HeaderComponent,
    PopupVideoComponent,
    PerfilComponent,
    FooterComponent,
    RegulamentomodalComponent,
    ContagemRegressivaComponent,
    LoaderComponent,
    HeaderUserComponent,
    CardRankingExtratoComponent,
    PlacarComponent,
    RegulamentoSorteioComponent,
    CadastroCpfComponent,
    ModalRegrasComponent,
    BannerPrincipalComponent,
    DoarComponent,
    ModalComponent,
    QrScanComponent,
    Qrscan2Component,
    Qrscan3Component,
    ModalEnderecoComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxUiLoaderModule,
    FontAwesomeModule,
    CurrencyMaskModule,
    CountdownModule,
    AutocompleteLibModule,
    QRCodeModule,
    NgQrScannerModule,
    NgxScannerQrcodeModule,
    ZXingScannerModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot(),
  ], 
  exports: [
    HeaderComponent,
    PopupVideoComponent,
    PerfilComponent,
    FooterComponent,
    RegulamentomodalComponent,
    ContagemRegressivaComponent,
    LoaderComponent,
    HeaderUserComponent,
    CardRankingExtratoComponent,
    PlacarComponent,
    RegulamentoSorteioComponent,
    CadastroCpfComponent,
    ModalRegrasComponent,
    BannerPrincipalComponent,
    DoarComponent,
    ModalComponent,
    QrScanComponent,
    Qrscan2Component,
    Qrscan3Component,
    ModalEnderecoComponent
  ]
})


export class IncludesModule { }
