import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { CadastroComponent } from './cadastro/cadastro.component';
import { LoginComponent } from './login/login.component';
import { PrimeiroAcessoComponent } from './primeiro-acesso/primeiro-acesso.component';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { DescadastrarComponent } from './descadastrar/descadastrar.component';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';


import { IonicModule } from '@ionic/angular';

import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { IncludesModule } from '../includes/includes.module';



@NgModule({
  declarations: [
    CadastroComponent,
    LoginComponent,
    PrimeiroAcessoComponent,
    ConfirmacaoComponent,
    DescadastrarComponent,    
  ],
  imports: [
    AuthRoutingModule,
    IncludesModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxUiLoaderModule,
    FontAwesomeModule,
    AutocompleteLibModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  exports: [
  ],
  providers: [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: LOCALE_ID, useValue: 'pt-BR'},
  ],
})
export class AuthModule { }
