import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { PerfilPageComponent } from './perfil/perfil.component';
import { IndiqueComponent } from './indique/indique.component';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { IncludesModule } from '../includes/includes.module';



@NgModule({
  declarations: [
    PerfilPageComponent,
    IndiqueComponent,
  ],
  imports: [
    ProfileRoutingModule,
    IncludesModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxUiLoaderModule,
    FontAwesomeModule,
    AutocompleteLibModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: LOCALE_ID, useValue: 'pt-BR'},
  ],
})
export class ProfileModule { }
