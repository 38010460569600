import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit {

  user_id;
  pontos;
  perfil;
  progress: number = 30;

  ranking:any = [];


  is_desktop = false;
  regulamento = false;

  constructor( private http:HttpClient, private router:Router, private location:Location, private route: ActivatedRoute ) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');

    if(!this.user_id){
      this.router.navigate(['/login']);
    }
    
    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }


    this.obter_ranking();
  }



  obter_ranking() {
    let url = 'participantes_iclub/obter_ranking';
  
    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      this.ranking = data;
    });
  }
  

  goback(){
    this.location.back();
  }

}
