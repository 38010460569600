import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'modal-endereco',
  templateUrl: './modal-endereco.component.html',
  styleUrls: ['./modal-endereco.component.scss'],
})

export class ModalEnderecoComponent implements OnInit {

  user_id;
  endereco:any = [];
 
  @Output() selecao = new EventEmitter();

  constructor( private router : Router, private http:HttpClient ) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.obter_endereco();
  }

  confirmarEndereco() {
    this.selecao.emit({ confirmar: true });
  }

  editarEndereco() {
    this.selecao.emit({ editar: true });
  }
  
  obter_endereco(){
    this.http.get(environment.API_URL.concat('participantes/obter_endereco/'+this.user_id)).subscribe( (data:any) => {
        if(data.status == 1){
            console.log(data);
            this.endereco = data.endereco;
        }
        else{
            console.log(data);
        }
    })
  }

}
