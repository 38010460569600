import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgForm , UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'cadastro-pontos',
  templateUrl: './cadastro-pontos.component.html',
  styleUrls: ['./cadastro-pontos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CadastroPontosComponent implements OnInit {

  user_id;
  user_name;
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

  pontos = 0;
  moedas = 0;
  loaded_data = false;

  pontos_ganhos;
  moedas_ganhos;
  modal_ganhou = false;

  isUploading = false;

  confirmacaoAceita: boolean = false;

  modal_aviso = false;
  
  scannear = false;
  regulamento = false;
  is_desktop = false;
  debug = '';
  carregando = true;

  imageSrc: any;
  myForm = new UntypedFormGroup({
    file: new UntypedFormControl('', [Validators.required]),
    fileSource: new UntypedFormControl('', [Validators.required])
});

  
  constructor(private router: Router, private http: HttpClient) {  }
  

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    this.obter_dados();
  }


  obter_dados() {

    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
      this.loaded_data = true;
    });

  }

  animacao_saldo() {
    this.modal_ganhou = true;
    setTimeout(() => { 
      this.modal_ganhou = false 
      location.reload();
    }, 5000);
 

  }

  atualizar_saldos($event) {

    setTimeout(() => {
      this.modal_ganhou = false;
      this.router.navigate(['/home']);
    }, 4000);

  }

  abrir_cam(){

    if(this.scannear){
      this.scannear = false;
      setTimeout(()=>{
        this.scannear = true;
      }, 1000);
    }
    else{
      this.scannear = true;
    }

  }

  enviar_qr(event){

    if(event){

      console.log('Iniciando requisição HTTP...');
      this.debug += 'Leitura completa, validando dados...';

      this.http.post(environment.API_URL.concat('pontos/cadastrar_codigo_qr'), {
        codigo_qr: event, 
        id_participante: this.user_id
      }).subscribe((data: any) => {

        this.carregando = false;
        console.log('Requisição HTTP completada.');
        this.debug += "<br> Dados Obtidos";
        
        if(data.status === 1){
          this.debug += "<br> Parabéns, você ganhou "+data.pontos+" pontos e "+data.moedas+" moedas!";
          this.pontos_ganhos = data.pontos;
          this.moedas_ganhos = data.moedas;
          this.animacao_saldo();
          this.playCoinSound();
        }
        else{
          this.debug += "<br>" + JSON.stringify(data.msg);
        }
      }, (error) => {
        this.carregando = false;
        console.error('Erro na requisição HTTP:', error);
        this.debug += "<br><small> Erro, tente novamente em alguns minutos. <br> contacte o administrador do sistema caso persista</small>";
      });

    }
    

  }
  

  playCoinSound() {
    let audio = new Audio('assets/audio/audioCoin.mpeg');
    audio.play();
  }

  upload_image(event){
    const reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      let ext = file.name.split('.')[1];

      switch(ext){
        case "png":
        case "jpg":
        case "jpeg":

          reader.readAsDataURL(file);
         
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.myForm.patchValue({
              fileSource: reader.result
            });
            this.submit();
          };

        break;

        default:
          alert("Faça upload de uma imagem JPG ou PNG.");
          return;
        break;
      }

    }

  }

  submit() {
    this.isUploading = true;
    this.http.post(environment.API_URL.concat('pontos/upload_foto_qr/' + this.user_id), this.myForm.value)
        .subscribe(
            (data: any) => {
                if (data.status == 1) {
                 this.modal_aviso = true;
                } else {
                    alert(data.erro);
                    console.log(data);
                }
            }
        );
}

redirectHome(){
  this.modal_aviso = false;
  this.router.navigate(['/home']);


}	  

}
