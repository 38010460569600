import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import * as $ from 'jquery';
import { Location } from '@angular/common';

@Component({
  selector: 'app-indique',
  templateUrl: './indique.component.html',
  styleUrls: ['./indique.component.scss'],
})
export class IndiqueComponent implements OnInit {

  perfil;


  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  user_name;

  indicados = []
    

  cad_cpf = false;
  user_id;

  //wpp ou qr
  metodo = "wpp";
  is_desktop = false;

  regulamento = false;
  linkIndicacao; 
  copiado = false;

  data = new Date();

  constructor(private http: HttpClient, private router: Router, private location: Location, private route: ActivatedRoute) { }

  ngOnInit() {
    this.user_id = localStorage.getItem("userId");
    this.perfil = parseInt(localStorage.getItem("perfil"));
    this.user_name = localStorage.getItem('userName');

    let rota: string = this.route.snapshot.url.toString();

    if (rota.includes('desktop')) {
      this.is_desktop = true;
    }

    if (!this.user_id)
      this.router.navigate(['/login']);

    const url = window.location.hostname;
    this.linkIndicacao =(url+'/indicacao/'+this.user_id);
   
    this.obter_indicados();
  }


  isPar(index: number): boolean {
    return index % 2 === 0; 
  }

  obter_indicados() {

    let url = 'cadastro/obter_indicados/' + this.user_id;

    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      if (data.status == 1) {
        console.log(data);
        this.indicados = data.indicados;
      }
    });

  }

  atualizar() {

    this.obter_indicados();
    alert("Lista atualizada com sucesso");

  }

  share() {
    if (navigator.share !== undefined) {
      navigator.share({
        title: 'ZEN',
        text: 'Faça seu cadastro agora!',
        url: 'https://clubevocemaiszen.com.br/',
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  modal_cpf(meio) {
    this.metodo = meio;
    this.cad_cpf = true;
  }

  goback() {
    this.location.back();
  }

  copiarParaAreaDeTrabalho() {
    this.copiado = true;
    // Verifique se há suporte para a execução do comando "copy" na área de transferência
    const inputElement = document.createElement('input');
    inputElement.value = this.linkIndicacao;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
  }

  abrirLinkNoWhatsApp() {
    if(window.confirm("Atenção, você será redirecionado para o WhatsApp.")){

      var texto = "Cadastre-se no Clube você mais ZEN: "+window.location.href.replace('indique','indicacao/'+this.user_id);
      var link = "whatsapp://send?text=" + encodeURIComponent(texto);
      window.open(link);

    }
  }

}
