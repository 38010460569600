import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OngsComponent } from './ongs/ongs.component';
import { FaqComponent } from './faq/faq.component';
import { IndexComponent } from './index/index.component';
import { LandingComponent } from './landing/landing.component';
import { RankingComponent } from './ranking/ranking.component';

const routes: Routes = [
  {    path: '',                  component: LandingComponent},
  {    path: 'home',              component: HomeComponent },
  {    path: 'ongs',              component: OngsComponent},
  {    path: 'faq',               component: FaqComponent},
  // {    path: 'index',             component: IndexComponent},
  // {    path: 'ranking',           component: RankingComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
