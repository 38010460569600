import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

interface AccordionItem {
  title: string;
  content: string;
  expanded: boolean;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  public accordionItems = [ ];

  user_id;
  user_name;
  
  perfil;
  public items:any = [];

  @Input() limite: string;

  regulamento = false;
  constructor(private router:Router, private http: HttpClient, private route:ActivatedRoute) {
    
  }
  
  ngOnInit() {
    
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    //this.perfil = localStorage.getItem('perfil');
    this.perfil = 1;
    this.obter_faq();
  }


  toggleItem(item: AccordionItem): void {
    item.expanded = !item.expanded;
  }

  obter_faq(){
    this.http.get<any>(environment.API_URL.concat('faq/index/'+this.perfil)).subscribe( data =>{
        if(this.limite){
            this.accordionItems = data.slice(0,this.limite);
        } else {
            this.accordionItems = data;
        }
    });
}
}
