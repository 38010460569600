import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import * as console from 'console';


@Component({
  selector: 'app-leilao',
  templateUrl: './leilao.component.html',
  styleUrls: ['./leilao.component.scss'],
})
export class LeilaoComponent implements OnInit {

  leiloes = [];
  lancesRecentes = [];
  leilaoSelecionado: any; 

  mostrarLanceContainer: boolean = false;

  slidesProdutosMobile = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
    },
    pagination: {
      el: '.swiper-pagination-shares',
    },
  }

  slidesProdutosDesktop = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 3,
    spaceBetween: 30, 
    navigation: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    }
  };
  


  user_id;
  user_name;
  pontos;

  lance_minimo = 100;
  lance_atual = 100;
  total_moedas: number;
  @Input() moedas;

  constructor(private http:HttpClient, private router:Router) {}

  
  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    //this.perfil = localStorage.getItem('perfil');

    if (!this.user_id) {
      this.router.navigate(['/login']);
    }

    this.obter_leiloes();

    this.obter_dados();
  }


  obter_dados() {

    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
    });

  }

  obter_leiloes() {
    this.http.get<any>(environment.API_URL.concat('leiloes/obter_ativos')).subscribe(data => {
      this.leiloes = data.leiloes;
    });
  }

  fazer_lance() {
    const dados = {
      user_id: this.user_id,
      id_leilao: this.leilaoSelecionado.id,
      moedas: this.lance_atual
    };
  
    this.http.post<any>(`${environment.API_URL}leiloes/fazer_lance`, dados).subscribe(data => {
      if (data.status === 1) {
        this.obter_dados();
        this.obter_leiloes();
        this.mostrarLanceContainer = false;

      } else {
       // console.log('Erro ao fazer o lance:', data.mensagem);
      }
    });
  }
  

  abrirLeilao(leilaoSelecionado: any) {
    this.leilaoSelecionado = leilaoSelecionado;
    this.mostrarLanceContainer = true;

    const postData = {
      id_leilao: leilaoSelecionado.id,
      id_usuario: this.user_id // Garanta que user_id está definido corretamente
    };
    
    this.http.post<any>(`${environment.API_URL}leiloes/obter_leilao`, postData)
    .subscribe({
      next: (response) => {
        this.lancesRecentes = response.lances;
      },
      error: (error) => {
        //console.error('Erro ao obter detalhes do leilão:', error);
      }
    });


  }

  decrementar() {
    if (this.lance_atual > this.lance_minimo) {
      this.lance_atual -= 100;
    }
  }

  incrementar() {
    if (this.lance_atual + 10 <= this.moedas) {
      this.lance_atual += 100;
    }
  }


}
