import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-ongs',
  templateUrl: './ongs.component.html',
  styleUrls: ['./ongs.component.scss'],
})



export class OngsComponent implements OnInit {
  
  user_id;
  user_name;
  perfil;

  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

  pontos;
  loaded_data = false;


  ongs = [
    {
      'id': 1,
      'image': '/assets/amem1-1500w.jpg',
      'nome': 'AMEM - Associação Mulheres em Movimento',
      'description': 'A ONG sem fins lucrativos, fundada por Benedita Maria Garcia e outras mulheres, busca promover o empoderamento feminino, educação e geração de renda em comunidades vulneráveis, independente de classe social, sexo, etnia ou crença religiosa, através de apoio alimentar e diversas ações sociais.',
      'link': 'https://www.facebook.com/associacaomulheresemmovimento/',
      'url': 'https://primaveras.newmode.com.br/admin/api/campanhas_primaveras/slide/4'
    },
    {
      'id': 2,
      'image': '/assets/ong%20madre%20regina-1500w.jpg',
      'nome': 'Casa de Idosos Lar Madre Regina',
      'description': 'O Lar Madre Regina, inaugurado há 24 anos em celebração ao centenário das Irmãs de Santa Catarina no Brasil e gerido pela Rede Santa Catarina, busca garantir a dignidade dos idosos através de práticas de atendimento de qualidade, promovendo um envelhecimento ativo e feliz para os residentes.',
      'link': 'https://redesantacatarina.org.br/social/larmadreregina/SitePages/default.aspx/',
      'url': 'https://primaveras.newmode.com.br/admin/api/campanhas_primaveras/slide/5'
    }
  ]

  ongSelecionada = null;


  moedas;
  causas_apoiadas;
  moedas_doados;
  
  id_causa;
  agradecimento = false;
  msg;


  modalDoar:boolean = false;
  modalAtencao:boolean = false;

  constructor( private http: HttpClient) { }

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    this.perfil = localStorage.getItem('perfil');

    let foto_src = localStorage.getItem('foto_src');
    if(foto_src){
      this.foto_src = foto_src;
    }

    
    //this.obter_dados();
    this.counter_bonus();

    
  }


  obter_dados() {

    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
      this.loaded_data = true;
    });

  }


  modalDoarOpen(ong: any) {

    const tipo = localStorage.getItem('tipo');
    if(!tipo || tipo == '0'){
      this.modalAtencao = true;
      return;
    }

    this.id_causa = ong.id;
    this.agradecimento = false;
    this.modalDoar = true;
    this.ongSelecionada = ong;
  }
  
  fecharModalAtencao(){
    this.modalAtencao = false;
  }
  

  modalDoarClose(){
    this.agradecimento = false;
    this.modalDoar = !this.modalDoar;
  }


  


  fazer_doacao(e){
    
    if(this.moedas >= e.doado){

      this.id_causa = e.id_causa;
      let descricao = "Doação via app";
      
      this.http.post<any>(environment.API_URL.concat('pontos/fazer_doacao'), 
      {id_participante: this.user_id, moedas: e.doado, id_causa: this.id_causa, descricao : descricao}).subscribe( data => {

        if( data.status == 1 ){
          this.moedas -= parseFloat(e.doado);
          this.moedas_doados = this.moedas_doados + parseFloat(e.doado);
          this.causas_apoiadas ++;
  
          const audioWin = new Audio('assets/audio/audioCoin.mpeg');
          audioWin.play();
    
          this.agradecimento = true;   
        }
        else{
          alert('Infelizmente houve um erro ao processar sua doação, tente novamente em alguns instantes');
        }

      });
      
      
    }
    else{
      this.msg = "Saldo insuficiente";
    }
  }

  scroll(el: HTMLElement){
    el.scrollIntoView();
  }

  counter_bonus(){
    let count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    localStorage.setItem('count_navigate', String(count_navigate));
  }


}
