import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'banner-principal',
  templateUrl: './banner-principal.component.html',
  styleUrls: ['./banner-principal.component.scss'],
})
export class BannerPrincipalComponent implements OnInit {

  constructor() { }

  @Input() pagina = '';
  margin_extra;
  
  ngOnInit() {
    if(this.pagina == 'qrscan'){
      this.margin_extra = true;
    }
  }
  
}
