import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-doar',
  templateUrl: './doar.component.html',
  styleUrls: ['./doar.component.scss'],
})
export class DoarComponent implements OnInit {

  @Input() isOpen;
  @Input() agradecimento;
  @Input() saldo;
  @Input() id_causa;
  @Input() ongSelecionada;

  moedas = 5;
  @Output() doacao = new EventEmitter();
  @Output() fechar = new EventEmitter();
  @Output() mudar_causa = new EventEmitter();
  

  urlToShare: string;

  invalid:boolean = false;
  msg:string = null;
  url = window.location.href;

  ongs = [
    {
      'id': 1,
      'image': '/assets/amem1-1500w.jpg',
      'nome': 'AMEM - Associação Mulheres em Movimento',
      'description': 'A ONG sem fins lucrativos, fundada por Benedita Maria Garcia e outras mulheres, busca promover o empoderamento feminino, educação e geração de renda em comunidades vulneráveis, independente de classe social, sexo, etnia ou crença religiosa, através de apoio alimentar e diversas ações sociais.',
      'url': 'https://primaveras.newmode.com.br/admin/api/campanhas_primaveras/slide/4' 
    },
    {
      'id': 2,
      'image': '/assets/ong%20madre%20regina-1500w.jpg',
      'nome': 'Casa de Idosos Lar Madre Regina',
      'description': 'O Lar Madre Regina, inaugurado há 24 anos em celebração ao centenário das Irmãs de Santa Catarina no Brasil e gerido pela Rede Santa Catarina, busca garantir a dignidade dos idosos através de práticas de atendimento de qualidade, promovendo um envelhecimento ativo e feliz para os residentes.',
      'url': 'https://primaveras.newmode.com.br/admin/api/campanhas_primaveras/slide/5' 
    }
  ]

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {

    if(changes.isOpen){
      this.isOpen = changes.isOpen.currentValue;
    }
    if(changes.agradecimento){
      this.agradecimento = changes.agradecimento.currentValue;
    }
    if(changes.saldo){
      this.saldo = changes.saldo.currentValue;
    }

    if (changes.ongSelecionada) {
      this.ongSelecionada = changes.ongSelecionada.currentValue;
      this.urlToShare = this.ongSelecionada?.url || '';
      //console.log(this.ongSelecionada);

    }

    if(changes.msg){
      this.msg = changes.msg.currentValue;
    }

    setTimeout(function(){
        $('#modal_doar').css({ top : '65px' });
    }, 200);
  }
  close(){
    this.fechar.emit(true);
  }
  doar(){
    if(this.valida_valor()){
      this.doacao.emit({'doado' : Math.floor(this.moedas), "id_causa" : this.id_causa});
    }
  }

  valida_valor(){
    this.invalid = true;

    //console.log(this.saldo);
    //console.log(this.moedas);

    if( this.moedas < 5 ){
      this.msg = 'Valor mínimo: 5 Moedas';
      return false;
    }
    else if( this.saldo < this.moedas ){
      this.msg = 'Saldo insuficiente';
      return false;
    }
    this.msg = null;
    this.invalid = false;
    return true;
  }
  
}
