import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { PrimeiroAcessoComponent } from './primeiro-acesso/primeiro-acesso.component';


const routes: Routes = [
 
  {    path: 'login',             component: LoginComponent  },
  {    path: 'cadastro',          component: CadastroComponent  },
  {    path: 'cadastrar',         component: CadastroComponent  },
  {    path: 'indicacao/:id',     component: CadastroComponent  },
  {    path: 'primeiro-acesso',   component: PrimeiroAcessoComponent},
  

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { 


}
