import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BenefitsRoutingModule } from './benefits-routing.module';
import { ExtratoComponent } from './extrato/extrato.component';
import { DescontosComponent } from './descontos/descontos.component';
import { LeilaoComponent } from './leilao/leilao.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { BoletosComponent } from './boletos/boletos.component';
import { CadastroPontosComponent } from './cadastro-pontos/cadastro-pontos.component';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';

import { IonicModule } from '@ionic/angular';

import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CountdownModule } from 'ngx-countdown';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { QRCodeModule } from 'angularx-qrcode';
import { NgQrScannerModule, QrScannerComponent } from 'angular2-qrscanner';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { IncludesModule } from '../includes/includes.module';
import { DecodeHtmlPipe } from 'src/app/pipes/decodeHtml.pipe';


@NgModule({
  declarations: [
    ExtratoComponent,
    DescontosComponent,
    LeilaoComponent,
    ProdutosComponent,
    BoletosComponent,
    CadastroPontosComponent,
    DecodeHtmlPipe
   ],
  imports: [
    BenefitsRoutingModule,
    IncludesModule,
    CommonModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    NgbModule,
    NgxUiLoaderModule,
    FontAwesomeModule,
    CurrencyMaskModule,
    NgxUiLoaderModule,
    CountdownModule,
    FontAwesomeModule,
    AutocompleteLibModule,
    QRCodeModule,
    NgQrScannerModule,
    NgxScannerQrcodeModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: LOCALE_ID, useValue: 'pt-BR'},
  ],
})
export class BenefitsModule { }
