import { Component, Injectable, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popup-video',
  templateUrl: './popup-video.component.html',
  styleUrls: ['./popup-video.component.scss'],
}) @Injectable()

export class PopupVideoComponent implements OnInit {

  constructor(private modalController: ModalController, private route: ActivatedRoute, private router:Router) { }
  
  @Input() isOpen = true;
  @Input() titulo;
  @Input() video;
  @Input() moedas;
  @Input() pontos;
  @Input() timer;
  public liberado:boolean = false;
  
  @Output() ganhou = new EventEmitter();
  

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    //console.log(changes);
    if(changes.isOpen.currentValue){
      this.liberado = false;
      this.iniciar_contagem();
    }

  }

  liberar_fechamento(){
    this.liberado = true;
  }

  receber_premio(){
    this.liberado = false;
    this.ganhou.emit({moedas : this.moedas, pontos: this.pontos});
  }

  iniciar_contagem(){
    this.timer;
    let contagem = setInterval(()=>{
      this.timer -- ;
      if(this.timer <= 0){
        this.liberar_fechamento();
        clearInterval(contagem);
      }
    }, 1000)
  }


}
