import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

@Component({
  selector: 'contagem-regressiva',
  templateUrl: './contagem_regressiva.component.html',
  styleUrls: ['./contagem_regressiva.component.css']
})

export class ContagemRegressivaComponent implements OnInit {
  
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  @Input() data_fim: string;
  dateTo: any;
  constructor() { }

  
  public config = {
    leftTime : 900, 
    format : 'HH:m:s'
  }

  ngOnInit(): void {
    this.data_fim = '2022-12-31 00:00:00';
    let data_fim = new Date(this.data_fim.replace(' ', 'T'));
    this.config.leftTime = data_fim.getTime() / 1000;

    //this.countdown.begin()
  }

  handleEvent(e){
    console.log(e);
  }

}
